import { SimpleShowLayout, TextField,Datagrid } from 'react-admin';
export const CustomerShowFields =() => (
        <SimpleShowLayout>
            <TextField label="Identifiant" source="id" />
            <TextField label="Ancien Identifiant" source="externalId" />
            <TextField label="Nom du Client" source="name" />
            <TextField source="code" /> 
            <TextField source="referal" />
        </SimpleShowLayout>
);

export const CustomerListFields = () => (
    <Datagrid rowClick="show">
            <TextField label="Nom du Client" source="name" />
            <TextField source="code" /> 
            <TextField source="referal" />
        </Datagrid>
)