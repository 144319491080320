import { SimpleShowLayout,DateField,Datagrid,TextField,NumberField } from "react-admin";
import  {CentsField,PercentField}  from './NumberField';
export const PeriodShowFields = () => (
    <SimpleShowLayout>
            <DateField label="Date de début" source="dateStart" />
            <DateField label="Date de fin" source="dateEnd" />
            <TextField label="Nom du Contrat" source="contractName" />
            <TextField label="Nom du Client" source="customerName" />
            <NumberField label="Nombre de jours" source="workedDay" options={{ style: 'unit', unit:'day' }} />
            <PercentField label="% de Comission" source="periodPercent"  />
            <CentsField label="Chiffre d'affaire" source="periodTurnover"   />
            <CentsField label="Frais internes" source="internalFees"   />
            <CentsField label="Dépenses" source="periodExpense"   />
        </SimpleShowLayout>
);

export const PeriodListFields = () => (
    <Datagrid rowClick="show">
    <DateField label="Date de début" source="dateStart" />
    <DateField label="Date de fin" source="dateEnd" />
    <TextField label="Nom du Contrat" source="contractName" />
    <TextField label="Nom du Client" source="customerName" />
    <NumberField label="Nombre de jours" source="workedDay" options={{ style: 'unit', unit:'day' }} />
    <PercentField label="% de Comission" source="periodPercent" />
    <CentsField label="Chiffre d'affaire" source="periodTurnover"   />
    <CentsField label="Frais internes" source="internalFees"   />
    <CentsField label="Dépenses" source="periodExpense"   />
</Datagrid>
);