import  { NumberField,useRecordContext } from 'react-admin';
export const CentsField = (props:any) => {
    const record = useRecordContext(props);
    const options = { style:'currency', currency: 'EUR' }
    if (typeof record[props.source] !== 'number' ) {
        record[props.source] = 0;
    }
    record[props.source] = ( record[props.source] / 10 );
    return (
        <NumberField
            {...props}
            options={options}
            locales={"fr-FR"} 
            source={props.source}
        />
    );
};

export const PercentField = (props:any) => {
    const record = useRecordContext(props);
    const options = { style:'percent' }
    record[props.source] = ( record[props.source] / 10 );
    return (
        <NumberField
            {...props}
            options={options}
            locales={"fr-FR"} 
            source={props.source}
        />
    );
};

