import { SimpleForm,ReferenceInput,SelectInput,TextField,TextInput} from "react-admin";

export const ProjectCreateInputs = (props: any) => (
  <SimpleForm onSubmit={props.projectSave}>
    <TextInput label="Projet" source="name" isRequired />
    <ReferenceInput source="customer" reference="Customer">
      <SelectInput label="Client" optionText="name" isRequired />
    </ReferenceInput>
  </SimpleForm>
);

export const ProjectEditInputs = (props: any) => (
  <SimpleForm onSubmit={props.ProjectSave}>
    <ReferenceInput source="name" reference="Project">
      <SelectInput label="Nom" optionText="name" isRequired />
    </ReferenceInput>
    <ReferenceInput source="customer" reference="Customer">
      <SelectInput label="Client" optionText="name" isRequired />
    </ReferenceInput>
    <TextField label="Client" source="customerName" />
    <TextField label="Code" source="code" />
  </SimpleForm>
);
