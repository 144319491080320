import { SelectInput,SimpleForm,TextInput,ReferenceInput, AutocompleteInput } from "react-admin"

export const EmployeeCreateInputs = (props:any) => (
    <SimpleForm onSubmit={props.employeeSave}>
            <TextInput label="Prénom" source="firstName" sx={{width: "42%"}} isRequired />
            <TextInput label="Nom" source="lastName" sx={{width: "42%"}} isRequired />
            <TextInput type='email' label="Email : " source="email" sx={{width: "42%"}} isRequired />
            <TextInput label="Ancien identifiant : " source="externalId" sx={{width: "42%"}} />
            <TextInput label="Identifiant mooncard : " source="mooncardId" sx={{width: "42%"}} />
            <ReferenceInput reference="User" source="user">
                <AutocompleteInput label="Utilisateur Associé" optionText="firstName" />
            </ReferenceInput>
    </SimpleForm>
);

export const EmployeeEditInputs = (props:any) => (
    <SimpleForm onSubmit={props.employeeSave}>
            <TextInput label="Prénom" source="firstName" sx={{width: "42%"}} isRequired />
            <TextInput label="Nom" source="lastName" sx={{width: "42%"}} isRequired />
            <TextInput type='email' label="Email : " source="email" sx={{width: "42%"}} isRequired />
            <SelectInput disabled source='status' optionValue="status" choices={[{id:"status",name:"statut"}]} />
            <TextInput label="Ancien identifiant : " source="externalId" sx={{width: "42%"}} />
            <TextInput label="Identifiant mooncard : " source="mooncardId" sx={{width: "42%"}} />
            <ReferenceInput reference="User" source="user">
                <AutocompleteInput label="Utilisateur Associé" optionText="firstName" />
            </ReferenceInput>
    </SimpleForm>
)