import { Edit,useUpdate,Show,ListBase,Create,useCreate } from 'react-admin';
import { PeriodShowFields,PeriodListFields } from '../fields';
import { PeriodFilters } from '../filters';
import { PeriodCreateInputs,PeriodEditInputs } from '../inputs';
import ListToolbar from '../Toolbar';

export const PeriodShow = () => (
    <Show>
       <PeriodShowFields />
    </Show>
);

export const PeriodList = () => (
    <ListBase>
        <ListToolbar filters={PeriodFilters} />
        <PeriodListFields />
    </ListBase>
);

export const PeriodCreate =  () => {
    const [create] = useCreate()
    const periodSave = (data:any) => {create('Period',{data: {data}})}
    return(
        <Create>
          <PeriodCreateInputs periodSave={periodSave} />
        </Create>
    )
};

export const PeriodEdit = () => {
    const [update] = useUpdate()
    const periodSave:any = (data:any) => {update('Period',{data: {data},id:data.id})}
    return(
        <Edit>
            <PeriodEditInputs periodSave={periodSave} />
        </Edit>
    )
};
