import { Show,useUpdate,Edit } from 'react-admin';
import { UserShowFields } from '../fields';
import { UserEditInputs } from '../inputs/UserInputs';
export const UserShow = () => (
    <Show>
       <UserShowFields />
    </Show>
);

export const UserEdit = () => {
    const [update] = useUpdate()
    const userSave:any = (data:any) => {update('User',{data: {data},id:data.id})}
    return (
    <Edit>
        <UserEditInputs userSave={userSave} />
    </Edit>
)};