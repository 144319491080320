import { Admin,Resource} from 'react-admin';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import {authProvider} from './authProvider';
import { myTheme } from './theme';
import {HttpLink} from "@apollo/client";
import * as crud from './screens';
import { Component } from "react";
import Dashboard from "./Dashboard";
import { MyLayout } from "./MyLayout";

const header = localStorage.getItem('token')? {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token') || '') }` }:{};
const link = new HttpLink({
        headers: header,
        uri: process.env.REACT_APP_API_ENDPOINT
});
class App extends Component<{},{ dataProvider:any }> {
    constructor(props:any) {
        super(props);
        this.state = { dataProvider: null };
    }
    componentDidMount() {
        buildGraphQLProvider({ clientOptions: { link }})
            .then(dataProvider => this.setState({ dataProvider }));
    }
    render() {
        const { dataProvider } = this.state;
        if (!dataProvider) { return <div>Loading</div>; }
        return (
            <Admin layout={MyLayout} theme={myTheme} dashboard={Dashboard} title={"Manager BO"} dataProvider={dataProvider}  authProvider={authProvider} requireAuth>
                <Resource options={{ label: 'Employés' }}  name="Employee" list={crud.EmployeeList} create={crud.EmployeeCreate} show={crud.EmployeeShow} edit={crud.EmployeeEdit}   />
                <Resource options={{ label: 'Contrats' }}  name="Contract" list={crud.ContractList} create={crud.ContractCreate} show={crud.ContractShow} edit={crud.ContractEdit}  />
                <Resource options={{ label: 'Clients' }}   name="Customer" list={crud.CustomerList} create={crud.CustomerCreate} show={crud.CustomerShow} edit={crud.CustomerEdit} />
                <Resource options={{ label: 'Bulletins' }} name="Payslip"  list={crud.PayslipList}  create={crud.PayslipCreate}  show={crud.PayslipShow}  edit={crud.PayslipEdit} />
                <Resource options={{ label: 'Périodes' }}  name="Period"   list={crud.PeriodList}   create={crud.PeriodCreate}   show={crud.PeriodShow}   edit={crud.PeriodEdit} />
                <Resource options={{ label: 'Projets' }}   name="Project"  list={crud.ProjectList}  create={crud.ProjectCreate}  show={crud.ProjectShow}  edit={crud.ProjectEdit} />
                <Resource name="Statement" show={crud.StatementShow} />
                <Resource name="User"  show={crud.UserShow} edit={crud.UserEdit} />
            </Admin>
        );
    }
}

export default App;