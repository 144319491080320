import { SimpleForm,ReferenceInput,AutocompleteInput,BooleanInput,DateInput,FileInput } from 'react-admin';

export const PayslipCreateInputs = (props:any) => (
    <SimpleForm onSubmit={props.payslipSave}>
            <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="lastName" label="Nom de famille de l'Employé" fullWidth isRequired/>
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="firstName" label="Prénom de l'Employé" fullWidth isRequired disabled  />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Payslip">
                <AutocompleteInput optionText="externalId" label="Identifiant externe" fullWidth />
            </ReferenceInput>
            <DateInput parse={v => Date.parse(v)} label="Date" source="date" required />
            <BooleanInput label="Notifier" source="notify" />
            <FileInput label="Bulletin de salaire" source="mainFile" isRequired />
    </SimpleForm>
);

export const PayslipEditInputs = (props:any) => (
    <SimpleForm onSubmit={props.payslipSave}>
                <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="lastName" label="Nom de famille de l'Employé" fullWidth isRequired/>
                </ReferenceInput>
                <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="firstName" label="Prénom de l'Employé" fullWidth isRequired disabled  />
                </ReferenceInput>
                <ReferenceInput source="externalId" reference="Payslip">
                    <AutocompleteInput optionText="externalId" label="Identifiant externe" defaultValue={"externalId"} fullWidth />
                </ReferenceInput>
                <DateInput parse={v => Date.parse(v)} label="Date" source="date" defaultValue={"date"} required />
                <BooleanInput label="Notifier" source="notify" defaultValue={"notify"} />
                <FileInput label="Bulletin de salaire" source="mainFile" isRequired />
               
    </SimpleForm>
);