import {Edit,useUpdate,Show,ListBase,Create,useCreate } from 'react-admin';
import { PayslipFilters } from '../filters';
import  ListToolbar  from '../Toolbar';
import { PayslipShowFields, PayslipListFields } from '../fields';
import { PayslipCreateInputs,PayslipEditInputs } from '../inputs';
export const PayslipShow = () => (
    <Show>
        <PayslipShowFields />
    </Show>
);

export const PayslipList = () => (
    <ListBase>
        <ListToolbar filters={PayslipFilters} />
        <PayslipListFields />
    </ListBase>
);

export const PayslipCreate = () => {
    const [create] = useCreate()
    const payslipSave = (data:any) => {create('Payslip',{data: {data}})}
    return(
    <Create>
        <PayslipCreateInputs payslipSave={payslipSave} />
    </Create>)
    
};

export const PayslipEdit = () => {
    const [update] = useUpdate()
    const payslipSave:any = (data:any) => {update('Payslip',{data: {data},id:data.id})}
    return(
        <Edit>
           <PayslipEditInputs payslipSave={payslipSave} />
        </Edit>
)};
