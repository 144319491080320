import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AppBar, Logout, UserMenu } from 'react-admin';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';

const userInfos = JSON.parse(localStorage.getItem('userInfos')!);

const MyCustomIcon = () => (
    <Avatar
        sx={{
            height: 30,
            width: 30,
        }}
        src={userInfos.avatar}
    />
);

const ConfigurationMenu = React.forwardRef((props, ref :any) => {
    return (
        <MenuItem
            ref={ref}
            component={Link}
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
            to={`/User/${userInfos!.id}/show`}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
                Mon compte
            </ListItemText>
        </MenuItem>
    );
});

const MyUserMenu = (props :any) => (

<UserMenu {...props} icon={<MyCustomIcon />}
              id="menu-appbar"
              >
        <ConfigurationMenu />
        {/*<SwitchLanguage />*/}
        <Logout />
    </UserMenu>
);

export const MyAppBar = (props :any) => (
    <AppBar {...props} userMenu={<MyUserMenu {...props}/>}{...props}>
            <Typography variant="h6" id="react-admin-title " {...props}
                        color="inherit"
                        component="div"
                        sx={{ flexGrow: 1, justifyContent:'space-between',display:'flex',flexDirection:'row' }}
                         ><div>Mon espace Gestion</div> <div>{userInfos?.tenantName}</div>
            </Typography>
    </AppBar>
)

