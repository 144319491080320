import {Edit,useUpdate,Show,ListBase,Create,useCreate } from 'react-admin';
import { ProjectShowFields,ProjectListFields } from '../fields';
import { ProjectFilters } from '../filters';
import { ProjectCreateInputs, ProjectEditInputs } from '../inputs';
import ListToolbar from '../Toolbar';

export const ProjectShow = () => (
    <Show>
        <ProjectShowFields />
    </Show>
);

export const ProjectList = () => (
    <ListBase>
        <ListToolbar filters={ProjectFilters} />
        <ProjectListFields />
    </ListBase>
);

export const ProjectCreate =  (props:any) => {
    const [create] = useCreate()
    const projectSave = (data:any) => {create('Project',{data: {data}})}
    return(
        <Create>
            <ProjectCreateInputs projectSave={projectSave} />
        </Create>
    )
};

export const ProjectEdit = () => {
    const [update] = useUpdate()
    const projectSave:any = (datas:any) => {const data = {}
        update('Project',{data: {data},id:datas.id})
    }
    return(
    <Edit>
        <ProjectEditInputs projectSave={projectSave} />
    </Edit>
)};