import { Edit,Show,ListBase,Create,useCreate, useUpdate } from 'react-admin';
import { EmployeeFilters } from '../filters';
import  ListToolbar  from '../Toolbar';
import { EmployeeShowFields,EmployeeListFields } from '../fields';
import { EmployeeCreateInputs,EmployeeEditInputs } from '../inputs';
export const EmployeeShow = () => (
    <Show>
       <EmployeeShowFields />
    </Show>
);

export const EmployeeList = () => {
    return(
    <ListBase>
        <ListToolbar filters={EmployeeFilters} />
        <EmployeeListFields />
    </ListBase>
)};

export const EmployeeCreate =  () => {
    const [create] = useCreate()
    const employeeSave = (data:any) => {create('Employee',{data: {data}})}
    return(
        <Create>
            <EmployeeCreateInputs employeeSave={employeeSave} />
        </Create>
    )
};

export const EmployeeEdit = () => {
    const [update] = useUpdate()
    const employeeSave:any = (data:any) => {update('Employee',{data: {data},id:data.id})}
    return(
        <Edit>
            <EmployeeEditInputs employeeSave={employeeSave} />
        </Edit>
)};

