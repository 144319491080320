import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import  ReceiptLongIcon  from '@mui/icons-material/ReceiptLong';
import BackupTableIcon from '@mui/icons-material/BackupTable';

export const EmployeeIcon = BadgeTwoToneIcon,
      ContractIcon = HandshakeTwoToneIcon,
      CustomerIcon = ApartmentTwoToneIcon,
      PayslipIcon = ReceiptLongIcon,
      PeriodIcon = AccessTimeTwoToneIcon,
      ProjectIcon = ListAltTwoToneIcon,
      StatementIcon = BackupTableIcon,
      InfosIcon = InfoRoundedIcon
;