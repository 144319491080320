import { SimpleForm,ReferenceInput,AutocompleteInput, TextInput, DateInput,NumberInput,BooleanInput, FileInput} from 'react-admin';
import { CentsInput } from './NumberInput';
export const PeriodCreateInputs = (props:any) => (
    <SimpleForm onSubmit={props.periodSave}>
    <ReferenceInput source="customerName" reference="Customer">
        <AutocompleteInput optionText="name" label="Client" fullWidth isRequired />
    </ReferenceInput>
    <ReferenceInput source="project" reference="Project">
        <AutocompleteInput optionText="name" label="Projet" fullWidth isRequired />
    </ReferenceInput>
    <ReferenceInput source="contract" reference="Contract">
        <AutocompleteInput optionText="code" label="Contrat" fullWidth isRequired />
    </ReferenceInput>
    <ReferenceInput source="employee" reference="Employee">
        <AutocompleteInput optionText="firstName" label="Employé" fullWidth isRequired />
    </ReferenceInput>
    <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" defaultValue={"date"} isRequired   />
    <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" defaultValue={"date"}   isRequired />
    <NumberInput source="workedDay" label="Nombre de jours travaillés"  isRequired />
    <NumberInput source="periodPercent" label="Comission GW (%)" isRequired />
    <CentsInput source="periodTurnover" label="Chiffre d'affaires"  isRequired/>
    <CentsInput source="internalFees" label="Frais internes" />
    <CentsInput source="periodExpense" label="Dépenses" />
    <CentsInput source="periodChargeProvided" label="Charges" />
    <CentsInput source="baseAccount" label="Compte de réserve brut*" isRequired />
    <CentsInput source="taxedSalary" label="Salaire net imposable" />
    <div className="row">
                <CentsInput label="Charges patronales" source="employerContributions" />
                <CentsInput label="Charges salariales" source="employeeContributions" />
    </div>
            <CentsInput source="salary" label="Salaire" />
    <div className="row">
                <CentsInput source="peeAmount" label="Plan Épargne Entreprise" />
                <CentsInput source="perAmount" label="Plan Épargne Retraite" />
    </div>
            <CentsInput source="incomeTaxes" label="Impôts sur le revenu" />
            <CentsInput source="commercialFee" label="Comission apporteur d'affaires" />
            <FileInput source="craUrl" label="Compte rendu d'activité" />
            <FileInput source="payslipUrl" label="Bulletin de salaire" />
            <BooleanInput source="locked" label="Vérouillé" />
</SimpleForm>
);

export const PeriodEditInputs = (props:any) => (
    <SimpleForm onSubmit={props.periodSave}>
        <div className="row">
        <TextInput label="Client" source="customerName" disabled />
            <TextInput label="Contrat" source="contractName" disabled />
        </div>
        <div className="row">
            <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" defaultValue={"date"}  />
            <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" defaultValue={"date"}  />
        </div>
            <NumberInput source="workedDay" label="Nombre de jours travaillés" />
            <NumberInput source="periodPercent" label="Comission GW (%)" format={v => v*100} />
            <CentsInput  source="periodTurnover" label="Chiffre d'affaires" />
            <CentsInput  source="internalFees" label="Frais internes" />
            <CentsInput  source="periodExpense" label="Dépenses" />
            <CentsInput  source="periodChargeProvided" label="Charges" />
            <CentsInput  source="baseAccount" label="Compte de réserve brut*" />
            <CentsInput  source="taxedSalary" label="Salaire net imposable" />
            <div className="row">
                <CentsInput  label="Charges patronales" source="employerContributions" />
                <CentsInput  label="Charges salariales" source="employeeContributions" />
            </div>
            <CentsInput  source="salary" label="Salaire" />
            <div className="row">
                <CentsInput  source="peeAmount" label="Plan Épargne Entreprise" />
                <CentsInput  source="perAmount" label="Plan Épargne Retraite" />
            </div>
            <CentsInput  source="incomeTaxes" label="Impôts sur le revenu" />
            <CentsInput  source="commercialFee" label="Comission apporteur d'affaires" />
            <FileInput source="craUrl" label="Compte rendu d'activité" />
            <FileInput source="payslipUrl" label="Bulletin de salaire" />
            <BooleanInput source="locked" label="Vérouillé" />
    </SimpleForm>
);