import  { NumberInput } from 'react-admin';
export const CentsInput = (props:any) => {
    return (
        <NumberInput
            {...props}
            source={props.source}
            format={v => String(v / 100)}
            parse={v => parseFloat(v) * 100} 
        />
    );
};