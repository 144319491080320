// in src/MyMenu.js
import {Menu}  from "react-admin";
import * as icon from './icons';
import { styles } from './style';
export const MyMenu = () => (
     <Menu> 
        <Menu.DashboardItem sx={styles.menuItem} primaryText="Tableau de bord" />
        <Menu.Item sx={styles.menuItem} to="/employee"  primaryText="Employés" leftIcon={<icon.EmployeeIcon />} />
        <Menu.Item sx={styles.menuItem} to="/contract" primaryText="Contrats" leftIcon={<icon.ContractIcon />} />
        <Menu.Item sx={styles.menuItem} to="/customer" primaryText="Clients"  leftIcon={<icon.CustomerIcon />} /> 
        <Menu.Item sx={styles.menuItem} to="/payslip" primaryText="Bulletins" leftIcon={<icon.PayslipIcon />} />
        <Menu.Item sx={styles.menuItem} to="/period" primaryText="Périodes"   leftIcon={<icon.PeriodIcon />}/> 
        <Menu.Item sx={styles.menuItem} to="/project" primaryText="Projets"   leftIcon={<icon.ProjectIcon />}/> 
     </Menu>
);