import { AuthProvider } from 'react-admin';
import { ApolloClient, InMemoryCache, gql} from '@apollo/client';
import { isExpired, decodeToken } from "react-jwt";

const cache = new InMemoryCache();
const client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    uri: process.env.REACT_APP_API_ENDPOINT,
    // Provide some optional constructor fields
    name: 'react-api-manager-auth-client',
    version: '1.3',
    queryDeduplication: false,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
});

const CREATE_AUTH_TOKEN = gql`
    mutation createAuthToken($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;

export const authProvider :AuthProvider = {
    login:  async ({ username , password }  :any) => {
        try {
            const data = await client.mutate({
                mutation: CREATE_AUTH_TOKEN,
                variables: {
                    data: {
                        "username": username,
                        "password": password,
                    },
                }
            });
            const decodedToken :any = decodeToken(data.data.createAuthToken.token || '');
            localStorage.setItem('token',  JSON.stringify(data.data.createAuthToken.token))
            localStorage.setItem('userInfos',  JSON.stringify({ id: decodedToken?.id || '', fullName: "decodedToken.firstName $(decodedToken.firstName)" , tenant: decodedToken.tenant, tenantName:decodedToken.tenantName, organisation: '', avatar: decodedToken.avatarImage }))
            localStorage.setItem('refreshToken',  JSON.stringify(data.data.createAuthToken.refreshToken))
            return { redirectTo: '/' };
        } catch (err) {
            console.log("catch", err);
        }
    },

    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userInfos');
        return Promise.resolve();
    },
    checkAuth: () => {
        return !isExpired( localStorage.getItem('token') || '' ) ? Promise.resolve() : Promise.reject({message: false})
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('userinfo') ||'');
            console.log('userIdentity')
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    }

};

