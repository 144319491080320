import {Datagrid, TextField, NumberField, SimpleShowLayout, DateField} from 'react-admin';
import  {CentsField} from './NumberField';

export const StatementListFields = () => (
        <Datagrid rowClick={"show"}>
            <TextField label="Année" source="year" />
            <DateField label="Période" source="period" options={{  year: 'numeric', month: 'long' }} />
            <TextField label="Nom du contrat" source="contractName" />
            <TextField label="Nombre de jours" source="workedDay" />
            <CentsField label="Compte de réserve" source="baseAccount" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="TJM" source="periodRate" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="CA" source="periodTurnover" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Net avant impôts" source="taxedSalary" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Salaire" source="salary" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Charges Patronales" source="employerContributions" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Charges Salariales" source="employeeContributions" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Impots sur le revenu" source="incomeTaxes" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField  source="periodPercent" locales={"fr-FR"} options={{ style: 'percent'}}  />
            <CentsField label="Frais GW" source="gwFee" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="Frais Internes" source="internalFees" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="PEE" source="peeAmount" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <CentsField label="PER" source="perAmount" locales={"fr-FR"} options={{ style: 'currency', currency: 'EUR' }}  />
            <TextField label="Autre" source="other" />
        </Datagrid>
);

export const StatementShowFields = () => (
    <SimpleShowLayout>
        <TextField label="Période" source="period" />
        <TextField label="Nom du contrat" source="contractName" />
        <NumberField label="Nombre de jours" source="workedDay" options={{style:'unit',unit:'day'}} />
        <CentsField label="Compte de réserve" source="baseAccount" />
        <CentsField label="TJM" source="periodRate" />
        <CentsField label="CA" source="periodTurnover" />
        <CentsField label="Net avant impôts" source="taxedSalary" />
        <CentsField label="Salaire" source="salary" />
        <CentsField label="Charges Patronales" source="employerContributions" />
        <CentsField label="Charges Salariales" source="employeeContributions" />
        <CentsField label="Impots sur le revenu" source="incomeTaxes" />
        <CentsField  source="periodPercent"  options={{ style: 'percent'}}  />
        <CentsField label="Frais GW" source="gwFee" />
        <CentsField label="Frais Internes" source="internalFees" />
        <CentsField label="PEE" source="peeAmount" />
        <CentsField label="PER" source="perAmount" />
        <TextField label="Autre" source="other" />
    </SimpleShowLayout>
);