import { Edit,useUpdate,Show,ListBase,Create,useCreate } from 'react-admin';
import { ContractFilters } from '../filters';
import  ListToolbar  from '../Toolbar';
import { ContractShowFields,ContractListFields } from '../fields'; 
import { ContractCreateInputs,ContractEditInputs } from '../inputs/ContractInputs';
export const ContractShow = () => (
    <Show>
        <ContractShowFields />
    </Show>
);

export const ContractList = () => (
    <ListBase>
        <ListToolbar filters={ContractFilters} />
        <ContractListFields />
    </ListBase>
);

export const ContractCreate =  () => {
    const [create] = useCreate()
    const contractSave = (data:any) => {create('Contract',{data: {data}})}
    return(
    <Create>
        <ContractCreateInputs contractSave={contractSave} />
    </Create>)
    
};

export const ContractEdit = () => {
    const [update] = useUpdate()
   
    const contractSave:any = (datas:any) => {
        const data = {
            customerName: datas.customerName,
            dateStart: datas.dateStart,
            dateEnd: datas.dateEnd,
            employeeFirstName: datas.employeeFirstName,
            employeeLastName: datas.employeeLastName
        }
        update('Contract',{data: {data},id:datas.id})
    }
    return(
    <Edit>
        <ContractEditInputs contractSave={contractSave} />
    </Edit>
)};