import Card from '@mui/material/Card';
import { Title } from "react-admin";
import { styles } from './style';

const Dashboard = () => {
    const cards = [{name:"En attente", values:["test"]},{name:"Nouveau", values:["test"]},{name:"Effectué",values:["test"]},{name:"Infos",values:["test"]}]
    return(
        <div>
            <Title title="Tableau de Bord" />
            <Card sx={[styles.top,styles.textCenter]}>
                    <p>Accès Rapide</p>
                    <Card sx={[styles.row,styles.around]}>
                        { cards.map((card) =>
                            <Card sx={[styles.card]}>
                                <p>{card.name}</p>
                                <li>{card.values}</li>
                            </Card>
                        )}
                    </Card>
            </Card>
        </div>
   )};

export default Dashboard;