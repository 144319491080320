import { Edit,useUpdate,Show,ListBase,Create,useCreate } from 'react-admin';
import { CustomerFilters } from '../filters';
import  ListToolbar  from '../Toolbar';
import { CustomerShowFields, CustomerListFields } from '../fields';
import { CustomerCreateInputs, CustomerEditInputs } from '../inputs';
export const CustomerShow = () => (
    <Show>
        <CustomerShowFields />
    </Show>
);

export const CustomerList = () => (
    <ListBase>
        <ListToolbar filters={CustomerFilters} />
        <CustomerListFields />
    </ListBase>
);

export const CustomerCreate =  (props:any) => {
    const [create] = useCreate()
    const customerSave = (data:any) => {
            create('Customer',{data: {data}})
    }
    
    return(
    <Create>
       <CustomerCreateInputs customerSave={customerSave} />
    </Create>)
    
};

export const CustomerEdit = () => {
    const [update] = useUpdate()
    const customerSave:any = (data:any) => {update('Customer',{data: {data},id:data.id})}
    return(
    <Edit>
        <CustomerEditInputs customerSave={customerSave} />
    </Edit>
)};
