import { SimpleForm,DateInput,BooleanInput,ReferenceInput,TextInput,AutocompleteInput } from 'react-admin';
import { CentsInput } from './NumberInput';
export const ContractCreateInputs = (props:any) => (
    <SimpleForm onSubmit={props.contractSave}>
            <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" fullWidth required />
            <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" fullWidth required />
            <CentsInput label="Taux journalier Moyen" source="rate" fullWidth required />
            <BooleanInput label="verouillé" source="locked" />
            <BooleanInput label="is_amendment ?" source="is_amendment" />
            <TextInput label="Mode de paiement" source="billingMode" fullWidth />
            <ReferenceInput source="customer" reference="Customer"  >
                <AutocompleteInput optionText="name" label="Client" fullWidth  isRequired />
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee" isRequired={true}>
                <AutocompleteInput optionText="firstName" label="Prénom du salarié" fullWidth isRequired />
         </ReferenceInput>
         <ReferenceInput source="employee" reference="Employee">
                <AutocompleteInput optionText="lastName" label="Nom de famille du salarié" fullWidth isRequired />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Contract">
             <AutocompleteInput optionText="externalId" label="Identifiant externe du contrat" fullWidth />
            </ReferenceInput>
</SimpleForm>
);

export const ContractEditInputs = (props:any) => (
    <SimpleForm onSubmit={props.contractSave}>
            <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" fullWidth  />
            <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" fullWidth  />
            <CentsInput label="Taux journalier Moyen" source="rate" fullWidth  />
            <BooleanInput label="verouillé" source="locked" />
            <BooleanInput label="is_amendment ?" source="is_amendment" />
            <TextInput label="Mode de paiement" source="billingMode" fullWidth />
            <ReferenceInput source="customer" reference="Customer"  >
                <AutocompleteInput optionText="name" label="Client" fullWidth   />
            </ReferenceInput>
            <ReferenceInput source="project" reference="Customer"  >
                <AutocompleteInput optionText="project" label="Projet" fullWidth   />
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee" >
                <AutocompleteInput optionText="firstName" label="Prénom du salarié" fullWidth  />
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee">
                <AutocompleteInput optionText="lastName" label="Nom de famille du salarié" fullWidth  />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Contract">
             <AutocompleteInput optionText="externalId" label="Identifiant externe du contrat" fullWidth />
            </ReferenceInput>
    </SimpleForm>
)