import { TextInput, SelectInput,DateInput,BooleanInput, NumberInput } from 'react-admin';

export const EmployeeFilters:any = [
    <TextInput label='Prénom' source='firstName' alwaysOn  />, 
    <TextInput label='Nom De Famille' source='lastName' />,
    <TextInput label='Adresse Email' source='email' type='email' />,
    <TextInput label='Code' source='code' />,
    <SelectInput label='Status' source='status' choices={[{id:'CREATED',name:'Crée'},{id:'DELETED',name:'Supprimé'}]} />,
    <DateInput parse={v => Date.parse(v)} label="Date de Création" source="createdAt" />,
    <DateInput parse={v => Date.parse(v)} label="Date de Modification" source="updatedAt" />,
    <TextInput label='Identifiant' source='ids' />  
];

export const ContractFilters:any = [
    <TextInput label='client' source='customerName' alwaysOn  />, 
    <TextInput label="Prénom de l'employé" source='employeeFirstName' />,
    <TextInput label="Nom de famille de l'employé" source='employeeLastName' />,
    <NumberInput label="Taux journalier" source="rate" />,
    <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" />,
    <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" />,
    <BooleanInput label="Vérouillé" source={"locked"} />,
    <TextInput label='ids' source='ids' />  
];

export const CustomerFilters:any = [
    <TextInput label='Client' source='name' alwaysOn  />, 
    <TextInput label='Identifiant' source='ids' />,  
    <TextInput label='Ancien Identifiant' source="externalId" />,
    <TextInput label='Code' source='code' />,
    <TextInput label='Référal' source='referal' />
];

export const PayslipFilters:any = [
    <TextInput label="Prénom de l'employé " source='employeeFirstName' alwaysOn />, 
    <TextInput label='ids' source='ids' />,
    <TextInput label='Ancien Identifiant' source="externalId" />,  
    <TextInput label='Code' source='code' />,
    <TextInput label="Nom De Famille de l'employé" source='employeeLastName' />,
    <TextInput type='email' label="Adresse Mail de l'employé" source='employeeEmail' />,
    <TextInput type='phone' label="Téléphone de l'employé" source='employeePhone' />,
    <DateInput parse={v => Date.parse(v)} label="Date" source="date" />,
    <BooleanInput label='notify ?' source='notify' />
];

export const PeriodFilters:any = [
    <TextInput label='Identifiant' source='ids' />,
    <TextInput label='Nom du Contrat' source='contractName' />,
    <TextInput label='Nom du Client' source='customerName' />,
    <TextInput label='Ancien Identifiant' source='externalId' />,
    <NumberInput label='Nombre de jours' source='workedDay' />,
    <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" alwaysOn />,
    <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" />,
    <TextInput label='Code' source='projectCode' />,
];

export const ProjectFilters:any = [
    <TextInput label='client' source='customerName' alwaysOn />,
    <TextInput label='code' source='code' />,
    <TextInput label='ids' source='ids' />  
];
