import { ChipField,Datagrid,DateField,TextField,ReferenceManyField,Tab,TabbedShowLayout,EmailField} from 'react-admin';
import  {CentsField}  from './NumberField';
import { PeriodListFields,PayslipListFields, StatementListFields } from '../fields';
import { Card,CardContent,Chip,Typography,Divider } from '@mui/material';
import * as icon from '../icons';
import { rowStyle,styles } from '../style';

const actualYear = new Date().getFullYear();
const pastYears = [actualYear,actualYear-1,actualYear-2,actualYear-3,actualYear-4,actualYear-5];

export const EmployeeShowFields = () => {
    return(
    <TabbedShowLayout>
    <Tab icon={<icon.InfosIcon/>} label="Informations">
        <Card sx={styles.ecardStyle}>
        <Divider variant="middle">Général</Divider>
            <CardContent sx={styles.around}>
                <div>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Nom & Prénom</Typography>
                    <Chip color="primary" variant="outlined" label={<div><TextField source="lastName" /> <TextField source="firstName" /></div>} />
                </div>
                <div>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Email</Typography>
                    <Chip color="primary" variant="outlined" label={<TextField label="Email : "  source="email" />} />
                </div>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.around}>
                <div>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Compte de réserve</Typography>
                    <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <CentsField source="statAccountAmount"   />} />
                </div>
                <div>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>CA Cumulé</Typography>
                    <Chip sx={styles.successTextColor} variant="outlined" label={<CentsField source="statGlobalBilledAmount"   />} />
                </div>
                <div>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Dépenses du mois</Typography>
                    <Chip sx={styles.errorTextColor} variant="outlined" label={<CentsField source="statExpensesOfMonth"   />} />
                </div>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Statuts</Divider>
            <CardContent sx={styles.around}>
              <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Crée le</Typography>       
                   <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<DateField label="Crée le : " source="createdAt" />}/>
              </div>
              <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Modifié le :</Typography>
                   <Chip sx={styles.successTextColor} variant="outlined" label={<DateField label="Modifié le : " source="updatedAt" />} />
              </div>
              <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Statut :</Typography>
                   <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField label="Statut : " source="status" />} />
              </div>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <CardContent sx={styles.around}>
                <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Employé</Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<TextField label="Identifiant employé : " source="id" />}/>
                </div>
                <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Utilisateur</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField label="Identifiant utilisateur : " source="user" />} />         
                </div>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.center}>
                <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Code</Typography> 
                     <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField source="code" color={"rgb(16,23,96)"}  />} />
                </div>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <CardContent sx={styles.around}>
                <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Ancien Identifiant</Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <TextField source="externalId" />}/>
                </div>
                <div><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Mooncard</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField  source="mooncardId" />} />
                </div>
            </CardContent>
        </Card>
    </Tab>
    <Tab icon={<icon.PayslipIcon/>} label="Bulletins">
        <ReferenceManyField reference='Payslip' target='employee' label="Bulletins de salaire">
            <PayslipListFields />
        </ReferenceManyField>
    </Tab>
    <Tab icon={<icon.PeriodIcon/>} label="Périodes">
        <ReferenceManyField reference='Period' target='employee' label="Périodes de travail">
            <PeriodListFields />
        </ReferenceManyField>
    </Tab>
    <Tab icon={<icon.StatementIcon/>} label="Décompte">
        <TabbedShowLayout syncWithLocation={false}>
            { pastYears.map((v,i) => 
                 <Tab label={`${v}`}>
                 <ReferenceManyField filter={{year: v}} reference='Statement' target='employee' label={`Décompte pour l'année ${v}`}>
                     <StatementListFields />
                 </ReferenceManyField>
                </Tab>
            ) }
        </TabbedShowLayout>
    </Tab>
</TabbedShowLayout>
)};

export const EmployeeListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick={"show"}>
    <TextField source="firstName" color={"rgb(16,23,96)"}  />
    <TextField source="lastName" color={"rgb(16,23,96)"}  />
    <EmailField source="email" color="rgb(250,16,105)" />
    <CentsField label="Compte de réserve" source="statAccountAmount"   color={"rgb(16,23,96)"}    />
    <CentsField label="Chiffre d'affaire cumulé" source="statGlobalBilledAmount"   color={"rgb(16,23,96)"}   />
    <CentsField label="Dépenses du mois" source="statExpensesOfMonth"   color={"rgb(16,23,96)"}  />
    <ChipField source="status" label="Statut"  />
    <DateField label="Date de création"  source="createdAt" />
    <DateField label="Date de Modification"  source="updatedAt" />
</Datagrid>
)