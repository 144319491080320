import { SimpleShowLayout,Datagrid,DateField,TextField,EmailField,FileField } from 'react-admin';
export const PayslipShowFields = () => (
    <SimpleShowLayout>
    <DateField label="Date" source="date" />
        <FileField src="mainFile.url" source="mainFile.url" title="PDF" label="Bulletin" download={true} />
        <TextField label="Prénom du salarié" source="employeeFirstName" />
        <TextField label="Nom de famille du salarié" source="employeeLastName" />
        <EmailField label="Email du salarié" source="employeeEmail" />
        <TextField label="Téléphone du salarié" source="employeePhone" />
    </SimpleShowLayout>
);

export const PayslipListFields = () => (
    <Datagrid rowClick="show">
    <DateField label="Date" source="date" />
    <FileField src="mainFile.url" source="mainFile.url" title="PDF" label="Bulletin" download={true} />
    <TextField label="Prénom du salarié" source="employeeFirstName" />
    <TextField label="Nom de famille du salarié" source="employeeLastName" />
    <EmailField label="Email du salarié" source="employeeEmail" />
    <TextField label="Téléphone du salarié" source="employeePhone" />
</Datagrid>
);