import { SimpleShowLayout,TextField,Datagrid } from "react-admin";
export const ProjectShowFields = () => (
        <SimpleShowLayout>
            <TextField label="Nom" source="name" />
            <TextField label="Client" source="customerName"/>
            <TextField label="Code" source="code" />
        </SimpleShowLayout>
);
export const ProjectListFields = () => (
        <Datagrid rowClick="show">
            <TextField label="Nom" source="name" />
            <TextField label="Client" source="customerName"/>
            <TextField label="Code" source="code" />
        </Datagrid>
);