import { green,red } from '@mui/material/colors';
export const styles = {
    primaryBgColor: {
        backgroundColor:"rgb(16,23,96)"
    },
    secondaryBgColor: {
        backgroundColor:"rgb(250,16,105)"
    },
    primaryTextColor: {
        color:"rgb(16,23,96)",
    },
    secondaryTextColor: {
        color:"rgb(250,16,105)"
    },
    successTextColor:{
        color: green[700]
    },
    errorTextColor: {
        color: red[500]
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    input: {

    },
    col: {
        display:"flex",
        flexDirection:"col"
    },
    center: {
        display:"flex",
        justifyContent: "center"
    },
    around: {
        display:"flex",
        justifyContent: "space-around"
    },
    top: {
        marginTop: 10
    },
    textCenter: {
        textAlign:"center"
    },
    card: {
        backgroundColor:"Lavender",
        marginTop: 5,
        marginBottom: 5,
        width: 200,
        height:200
    },
    menuItem: {
        color:"rgb(16,23,96)",
        "&:focus": {
            backgroundColor:"rgb(250,16,105)",
            color:"white",
            fontWeight:"bold"
        }
    },
    rowStyle: {
        backgroundColor:"rgb(16,23,96)"
    },
    ecardStyle: {
        display:"flex",flexDirection:"column", justifyContent:"space-around"
    }
}

export const rowStyle = (record:any,index:any) => ({
    backgroundColor:  index%2 === 0 ? 'rgba(16,23,96,0.3)' : 'white',
})