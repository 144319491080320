import { FilterForm, FilterButton, CreateButton } from 'react-admin';
const ListToolbar = (filters:any) => {
    return(
    <div>
        <FilterForm filters={filters.filters} />
        <div>
            <FilterButton filters={filters.filters} />
            <CreateButton />
        </div>
    </div>
)};

export default ListToolbar;