import { SimpleShowLayout,TextField,BooleanField,DateField,Datagrid } from 'react-admin';
import  {CentsField}  from './NumberField';
export const ContractShowFields = () => (
    <SimpleShowLayout>
    <TextField label="Client" source="customerName" />
        <DateField label="Date de début" source="dateStart" />
        <DateField label="Date de fin" source="dateEnd" />
        <CentsField label="Taux Journalier" source="rate" />
        <TextField label="Prénom du Salarié" source="employeeFirstName" />
        <TextField label="Nom de Famille Salarié" source="employeeLastName" />
        <TextField label="Projet" source="project" />
        <TextField label="Mode de Paiement" source="billingMode" />
        <TextField label="Ancien Identifiant" source="externalId" />
        <BooleanField label="Verrouillé" source="locked" />
        <BooleanField label="Amendment" source="is_amendment" />
    </SimpleShowLayout>
);

export const ContractListFields = () => (
    <Datagrid rowClick="show">
        <TextField label="Client" source="customerName" />
        <DateField label="Date de début" source="dateStart" />
        <DateField label="Date de fin" source="dateEnd" />
        <CentsField label="Taux Journalier" source="rate" />
        <TextField label="Prénom du Salarié" source="employeeFirstName" />
        <TextField label="Nom de Famille Salarié" source="employeeLastName" />
        <BooleanField label="Verrouillé" source="locked" />
    </Datagrid>
);