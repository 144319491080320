import { SimpleForm,TextInput,required } from 'react-admin';

export const CustomerCreateInputs = (props:any) => (
    <SimpleForm onSubmit={props.customerSave}>
            <TextInput label="Nom de l'entreprise" source='name'  validate={[required()]} fullWidth />
            <TextInput label="Identifiant externe" source='externalId' fullWidth />
        </SimpleForm>
);

export const CustomerEditInputs = (props:any) => (
    <SimpleForm onSubmit={props.customerSave}>
            <TextInput label="Nom de l'entreprise" source='name'  validate={[required()]} fullWidth />
            <TextInput label="Identifiant externe"  source='externalId' fullWidth />
        </SimpleForm>
);